<template>
  <v-dialog v-model="visible" transition="dialog-top-transition" width="500">
    <v-card>
      <v-card-title class="text-h5 primary">
        <p style="color: #ffffff">Parcel Details Not Found</p>
      </v-card-title>
      <v-card-text>
        <p>
          Apologies, it appears that the parcel details of this lot plan is not available.
        </p>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const EVENT_NAME = "close-parcel-details-not-found-dialog";

export default {
  props: {
    value: Boolean,
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit(EVENT_NAME);
      },
    },
  },
  methods: {
    closeDialog: function () {
      this.$emit(EVENT_NAME);
    },
  },
};
</script>
